import React, { createContext, useContext, useMemo, useState } from 'react';
import usePageContext from '@/utils/context/page';
import ProductCategoryCardList from '@/components/ProductCategory/List/ProductCategoryCardList';
import CategoryHeader from '@/components/ProductCategory/CategoryHeader/CategoryHeader';
import CategoryFiltersDrawer, {
  CategoryFilterDrawerContext,
  CategoryFilterDrawerInitState,
} from '@/components/ProductCategory/CategoryFiltersDrawer/CategoryFiltersDrawer';
import { PageSeoRichBody } from '@/components/SeoRichBody/SeoRichBody';
import CategoryNavigation from '@/components/ProductCategory/CategoryNavigation/CategoryNavigation';
import { formatCfItems } from '@/helpers/formatCfItems';
import HeroBanner from '@/components/Hero/Banner/HeroBanner';
import useBreakpoints, {
  UseBreakpointsReturn,
} from '@/utils/hook/useBreakpoints';
import ShopItemProvider from '@/templates/ProductCategory.context';

export const APPLY_DISCOUNT_PRODUCT_FACET = 'APPLY_DISCOUNT_PRODUCT_FACET';
const HeroCategory = () => {
  const { components } = usePageContext();
  const props = useMemo(() => {
    try {
      const obj = formatCfItems(
        formatCfItems({ fields: { items: components } })?.hero,
      );
      return Object.keys(obj).length > 0 ? obj : null;
    } catch {
      return null;
    }
  }, [components]);

  return props ? <HeroBanner {...props} /> : null;
};

export const CategoryFilterSectionInitState = {
  opened: false,
};

export const CategoryFilterSectionContext = createContext<
  typeof CategoryFilterSectionInitState & { toggle: () => void; set: any }
>({
  ...CategoryFilterSectionInitState,
  set: () => {},
  toggle: () => {},
});

export const useCategoryFilterSection = () =>
  useContext(CategoryFilterSectionContext);

const ProductCategory = (_: { showFeatured: boolean }) => {
  // useStickyHeader('#desktop-sticky-category-navigation');
  const [categoryFilterSectionState, setCategoryFilterSectionState] = useState(
    CategoryFilterSectionInitState,
  );
  const categoryFilterDrawerState = useState(CategoryFilterDrawerInitState);
  const bp = useBreakpoints(
    ({ isDesktop }: Required<Omit<UseBreakpointsReturn, 'loading'>>) => {
      const defaultOpened =
        isDesktop &&
        new URLSearchParams(window.location.search).get('filter_open') ===
          'true';
      if (defaultOpened)
        setCategoryFilterSectionState((prev) => ({
          ...prev,
          opened: true,
        }));
    },
  );

  const toggleCategoryFilter = () => {
    if (bp.isDesktop && !bp.loading) {
      // @ts-ignore
      setCategoryFilterSectionState((prev) => ({
        ...prev,
        opened: !prev.opened,
      }));
    } else if (bp.isMobile && !bp.loading) {
      categoryFilterDrawerState[1]({
        ...categoryFilterDrawerState[0],
        keepMounted: true,
        opened: !categoryFilterDrawerState[0].opened,
        onClose: () => {
          categoryFilterDrawerState[1]({
            ...categoryFilterDrawerState[0],
            opened: false,
          });
        },
        padding: 30,
      });
    }
  };

  return (
    <ShopItemProvider>
      <CategoryFilterSectionContext.Provider
        value={{
          ...categoryFilterSectionState,
          set: setCategoryFilterSectionState,
          toggle: toggleCategoryFilter,
        }}>
        <CategoryFilterDrawerContext.Provider value={categoryFilterDrawerState}>
          {/*<HeroCategory />*/}
          <CategoryFiltersDrawer />
          <CategoryHeader />
          <ProductCategoryCardList />
        </CategoryFilterDrawerContext.Provider>
      </CategoryFilterSectionContext.Provider>
      <CategoryNavigation />
      <PageSeoRichBody />
    </ShopItemProvider>
  );
};

export default ProductCategory;
