import zIndexes from '@/utils/style/zIndex';
import { MantineTheme, Tuple } from '@mantine/core';
import { IThemeColors } from './interface';

const theme = {
  black: '#2D2D2D',
  fontFamily: 'Gilmer',
  colors: {
    black: [
      '#dedede',
      '#cacaca',
      '#adadad',
      '#686868',
      '#999999',
      '#545454',
      '#4a4a4a',
      '#414141',
      '#373737',
      '#2D2D2D',
    ],
    indigo: [
      '#5B78FF',
      '#4D6BFF',
      '#4164FF',
      '#364DFF',
      '#2B4BFF',
      '#213CCF',
      '#1C38CC',
      '#142D99',
      '#0D2680',
      '#051C66',
    ],
    white: Array(10).fill('#ffffff') as Tuple<string, 10>,
    title: Array(10).fill('#2D2D2D') as Tuple<string, 10>,
    lightGray: Array(10).fill('#F5F5F5') as Tuple<string, 10>,
    dimmed: Array(10).fill('#d1d1d1') as Tuple<string, 10>,
    titleDimmed: Array(10).fill('#585858') as Tuple<string, 10>,
    decimal: Array(10).fill('#8D8D8D') as Tuple<string, 10>,
    text: Array(10).fill('#585858') as Tuple<string, 10>,
    main: Array(10).fill('#1949E7') as Tuple<string, 10>,
    original: Array(10).fill('#EF233C') as Tuple<string, 10>,
    sun: Array(10).fill('#FEAE00') as Tuple<string, 10>,
    bluelight: Array(10).fill('#1949E7') as Tuple<string, 10>,
    reading: Array(10).fill('#EF233C') as Tuple<string, 10>,
    kids: Array(10).fill('#FC6806') as Tuple<string, 10>,
    sport: Array(10).fill('#61B232') as Tuple<string, 10>,
    'acetate-ss23-secondary': Array(10).fill('#FDF185') as Tuple<string, 10>,
    'acetate-ss23-primary': Array(10).fill('#F67600') as Tuple<string, 10>,
    hero: Array(10).fill('#EA7053') as Tuple<string, 10>,
    'sun-pastel': Array(10).fill('#FDF185') as Tuple<string, 10>,
    greenReview: Array(10).fill('#51B37F') as Tuple<string, 10>,
    greenWhatsapp: Array(10).fill('#47C756') as Tuple<string, 10>,
    yellowFresh: Array(10).fill('#FFFBD3') as Tuple<string, 10>,
    blueBF: Array(10).fill('#859FFD') as Tuple<string, 10>,
    'green-half-moon': Array(10).fill('#84966D') as Tuple<string, 10>,
    terracota: Array(10).fill('#DC693B') as Tuple<string, 10>,
    ss24blue: Array(10).fill('#5A6FA1') as Tuple<string, 10>,
    ss24blueContrast: Array(10).fill('#AACCE5') as Tuple<string, 10>,
    ss24brown: Array(10).fill('#E58B38') as Tuple<string, 10>,
    ss24brownContrast: Array(10).fill('#3D3741') as Tuple<string, 10>,
    ss24green: Array(10).fill('#529168') as Tuple<string, 10>,
    ss24greenContrast: Array(10).fill('#F5AE6C') as Tuple<string, 10>,
    ss24red: Array(10).fill('#9E5B50') as Tuple<string, 10>,
    ss24redContrast: Array(10).fill('#DAF56C') as Tuple<string, 10>,
    'af24-acetate-reading-primary': Array(10).fill('#FF9376') as Tuple<
      string,
      10
    >,
  } as unknown as IThemeColors,
  colorScheme: 'light',
  primaryColor: 'indigo',
  headings: {
    // fontFamily: 'Mont',
  },
  components: {
    Drawer: {
      styles: () => ({ root: { zIndex: zIndexes.drawer } }),
    },
    Modal: {
      styles: () => ({
        root: { zIndex: zIndexes.modal },
        inner: { zIndex: zIndexes.modal },
        overlay: { zIndex: zIndexes.modal },
      }),
    },
    Dialog: {
      styles: () => ({
        root: { padding: '0px !important', overflow: 'hidden' },
        closeButton: {
          backgroundColor: 'white',
          zIndex: 1,
        },
      }),
    },
  },
} as unknown as MantineTheme;

export default theme;
