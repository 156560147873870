import {
  Box,
  Divider,
  Flex,
  clsx,
  createStyles,
  useMantineTheme,
  Group,
  Rating,
} from '@mantine/core';
import React, { useEffect, useMemo, useState } from 'react';
import NoozText from '../Nooz/NoozText/NoozText';
import { useContainerStyles } from '@/utils/style/useContainer';
import NoozButton from '../Nooz/NoozButton/NoozButton';
import NoozBadge from '../Nooz/NoozBadge/NoozBadge';
import TextLink from '@/components/Link/TextLink';
import NoozIcon from '../Nooz/NoozIcon/NoozIcon';
import { IBlock, IButton, ILabel, ILink } from '@/@types/generated/contentful';
import { formatCfItems } from '@/helpers/formatCfItems';
import GoogleRest from '@/utils/services/GoogleRest';
import usePageContext from '@/utils/context/page';

const useStyles = createStyles((theme) => ({
  wrapper: {
    flexDirection: 'row',
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      flexDirection: 'column',
    },
  },
  label: {
    textAlign: 'center',
    fontSize: 60,
    width: '70%',
    lineHeight: 1.2,
    fontWeight: 900,
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      fontSize: 40,
      width: '100%',
    },
  },
  infoWrapper: {
    flexDirection: 'row',
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      flexDirection: 'column',
    },
  },
  openingDayInfo: {
    gap: 20,
    justifyContent: 'space-between',
    borderRadius: '10px',
  },
  stickyRetailInfo: {
    position: 'sticky',
    bottom: 0,
    borderTop: `1px solid ${theme.colors.gray[4]}`,
    alignSelf: 'flex-end',
    backgroundColor: theme.colors.dark[0],
  },
}));

export const RetailOpeningInfo = ({
  days,
  label,
}: {
  days: IBlock;
  label?: ILabel;
}) => {
  const { classes } = useStyles();

  const getCurrentDay = () => {
    const currentDate = new Date();
    return days?.fields.items?.[currentDate.getDay() - 1];
  };

  const currentDay = getCurrentDay();

  return (
    <Box w='100%'>
      {label ? (
        <NoozText
          mb='lg'
          size='lg'
          weight='bold'>
          {label.fields.text}
        </NoozText>
      ) : null}
      {/* @ts-ignore */}
      {days?.fields.items?.map((d: ILabel) => {
        const isCurrentDay = d.fields.key === currentDay?.fields.key;
        const day = d.fields.text?.split(' ')[0];
        const hours = d.fields.text?.replace(day || '', '');
        return (
          <Flex
            key={d.fields.key}
            px={10}
            py={4}
            bg={isCurrentDay ? 'gray.2' : ''}
            className={classes.openingDayInfo}>
            <NoozText
              size={16}
              weight='bold'>
              {day}
            </NoozText>
            <NoozText
              size={16}
              color='dark.8'>
              {hours}
            </NoozText>
          </Flex>
        );
      })}
    </Box>
  );
};

const RetailContactInfo = ({
  label,
  emailLink,
  phoneLink,
  link,
  address,
}: {
  label: ILabel;
  emailLink: ILink;
  phoneLink: ILink;
  link: ILink;
  address: IBlock;
}) => {
  return (
    <Box w='100%'>
      <NoozText
        mb='lg'
        size='lg'
        weight='bold'>
        {label.fields.text}
      </NoozText>
      <Flex
        mb='sm'
        gap='sm'
        align={'center'}>
        <NoozIcon
          size='lg'
          color='blue'
          iconKey='Phone'
        />
        <TextLink
          size={16}
          type='animated-underline'
          url={phoneLink.fields.urlLink}
          text={phoneLink.fields.label?.fields.text}
        />
      </Flex>
      <Flex
        mb='sm'
        gap='sm'
        align={'center'}>
        <NoozIcon
          size='lg'
          color='blue'
          iconKey='Mail'
        />
        <TextLink
          size={16}
          type='animated-underline'
          url={emailLink.fields.urlLink}
          text={emailLink.fields.label?.fields.text}
        />
      </Flex>
      <Flex
        mb='sm'
        gap='sm'
        align='start'>
        <NoozIcon
          size='lg'
          color='blue'
          iconKey='DirectionSign'
        />
        <Box>
          {address?.fields.items?.map((item, key: any) => {
            return (
              <NoozText
                key={key}
                size={16}>
                {(item as unknown as ILabel).fields.text}
              </NoozText>
            );
          })}
        </Box>
      </Flex>
      <TextLink
        size={16}
        color='blue'
        url={link.fields.urlLink}
        text={link.fields.label?.fields.text}
        type='underline'
      />
    </Box>
  );
};

const ReviewInfo = ({
  label,
  subLabel,
  link,
}: {
  label: ILabel;
  subLabel: ILabel;
  link: ILink;
}) => {
  const theme = useMantineTheme();
  const ctx = usePageContext() as any;
  const googleInfo = useMemo(
    () =>
      ctx?.googleInfo
        ? ctx?.googleInfo
        : {
            rating: '2.5',
            userRatingsTotal: 42,
          },
    [ctx],
  );
  return (
    <Box>
      <NoozText
        mb='lg'
        size='lg'
        weight='bold'>
        {label.fields.text}
      </NoozText>
      <Group mb='sm'>
        <Rating
          value={parseFloat(googleInfo?.rating || '2.5')}
          fractions={2}
          readOnly
        />
        <NoozText size={16}>
          {`${googleInfo?.rating || 2.5} ${subLabel.fields.text?.replace(
            '{{quantity}}',
            googleInfo.userRatingsTotal,
          )}`}
        </NoozText>
      </Group>
      <TextLink
        size={16}
        url={GoogleRest.reviewLink}
        text={link.fields.label?.fields.text}
        type='underline'
        color='blue'
      />
    </Box>
  );
};

const MainTitle = ({
  label,
  button,
  closedLabel,
  openedLabel,
}: {
  label: ILabel;
  button: IButton;
  closedLabel: ILabel;
  openedLabel: ILabel;
}) => {
  const { classes } = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const checkIfOpen = () => {
      const now = new Date();
      const dayOfWeek = now.getDay();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const totalMinutes = hours * 60 + minutes;

      const openingTime = 10 * 60;
      const closingTime = 19 * 60 + 30;
      if (
        dayOfWeek >= 0 &&
        dayOfWeek <= 6 &&
        totalMinutes >= openingTime &&
        totalMinutes < closingTime
      ) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };
    checkIfOpen();
    const intervalId = setInterval(checkIfOpen, 60000);
    return () => clearInterval(intervalId);
  }, []);

  const returnOpeningBadge = (isOpen: boolean) => {
    return (
      <NoozBadge
        text={
          isOpen
            ? (openedLabel.fields.text as string)
            : (closedLabel.fields.text as string)
        }
        textProps={{
          size: 'sm',
        }}
        badgeProps={{
          variant: 'dot',
          color: isOpen ? 'green' : 'red',
          radius: 'lg',
          size: 'xl',
          mb: 'sm',
        }}
        iconPosition='left'
      />
    );
  };

  return (
    <Flex
      direction='column'
      justify='center'
      align='center'
      mb='lg'
      style={{ flex: 1 }}>
      {isOpen ? returnOpeningBadge(true) : returnOpeningBadge(false)}
      <NoozText
        className={classes.label}
        mb='xl'>
        {label?.fields.text}
      </NoozText>
      {/* @ts-ignore */}
      <NoozButton
        urlLink={button?.fields.urlLink}
        function={button?.fields.function}
        color='terracota'
        miw={240}
        style={{ alignSelf: 'center' }}>
        {button?.fields.label?.fields.text}
      </NoozButton>
    </Flex>
  );
};

const RetailInfo = ({
  mainInfo,
  openingInfo,
  contactInfo,
  reviewInfo,
}: {
  mainInfo: IBlock;
  openingInfo: IBlock;
  contactInfo: IBlock;
  reviewInfo: IBlock;
}) => {
  const containerStyles = useContainerStyles();
  const { classes } = useStyles();

  const mainInfoItems = formatCfItems(mainInfo);
  const openingInfoItems = formatCfItems(openingInfo);
  const contactInfoItems = formatCfItems(contactInfo);
  const reviewInfoItems =
    (reviewInfo.fields?.items?.length || 0) > 0
      ? formatCfItems(reviewInfo)
      : undefined;

  return (
    <>
      <Flex
        className={clsx(containerStyles.classes.container, classes.wrapper)}
        direction='row'
        justify='space-between'
        gap='lg'>
        <MainTitle
          label={mainInfoItems.label}
          button={mainInfoItems.button}
          openedLabel={mainInfoItems.openedLabel}
          closedLabel={mainInfoItems.closedLabel}
        />
        <Flex
          direction='column'
          style={{ flex: 1 }}
          gap={30}>
          <Box>
            <Flex
              justify='space-between'
              className={classes.infoWrapper}
              gap={40}>
              <RetailOpeningInfo
                label={openingInfoItems.label}
                days={openingInfoItems.days}
              />
              <RetailContactInfo
                label={contactInfoItems.label}
                emailLink={contactInfoItems.emailLink}
                phoneLink={contactInfoItems.phoneLink}
                link={contactInfoItems.link}
                address={contactInfoItems.address}
              />
            </Flex>
          </Box>
          <Divider />
          {reviewInfoItems ? (
            <ReviewInfo
              label={reviewInfoItems.label}
              link={reviewInfoItems.link}
              subLabel={reviewInfoItems.subLabel}
            />
          ) : null}
        </Flex>
      </Flex>
    </>
  );
};

export default RetailInfo;
