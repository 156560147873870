import { Box, Flex, MediaQuery, ScrollArea } from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import useHeaderHeight from '@/utils/hook/useHeaderHeight';
import React, { memo } from 'react';
import { IBlock, ILinkFields } from '@/@types/generated/contentful';
import { formatCfItems } from '@/helpers/formatCfItems';
import Link from '@/components/Link/Link';
import { usePathname } from 'next/navigation';
import usePageContext from '@/utils/context/page';

const StickySubHeader = ({ sticky }: { sticky: IBlock }) => {
  const { locale } = usePageContext();
  const headerHeight = useHeaderHeight();
  const pathname = usePathname();

  const links = () => {
    const l: React.ReactNode[] = [];

    if (sticky?.fields.items) {
      for (const item of sticky.fields.items) {
        const { link, color, isNew } = formatCfItems(item);

        if (link) {
          l.push(
            <Link
              key={link.id}
              href={(link.fields as ILinkFields).slug?.fields.url || ''}>
              <Box
                pos={'relative'}
                pt={8}>
                <NoozText
                  weight={
                    pathname ===
                    `/${locale}/${
                      (link.fields as ILinkFields).slug?.fields.url
                    }`
                      ? 700
                      : 500
                  }
                  size={16}
                  color={
                    color?.fields?.classname ||
                    link?.fields.label?.fields.color?.fields?.classname
                  }
                  sx={
                    color?.fields?.gradientObject
                      ? { backgroundImage: `${color?.fields?.hex} !important` }
                      : undefined
                  }
                  gradient={color?.fields?.gradientObject}
                  variant={
                    color?.fields?.gradientObject
                      ? 'gradient'
                      : link?.fields.label?.fields.variant || 'text'
                  }>
                  {link?.fields.label?.fields.text}
                </NoozText>

                {isNew ? (
                  <NoozText
                    size={10}
                    color={'main'}
                    weight={700}
                    sx={{
                      position: 'absolute',
                      top: '0',
                      right: '0',
                      transform: 'translateX(50%)',
                    }}>
                    New
                  </NoozText>
                ) : null}
              </Box>
            </Link>,
          );
        }
      }
    }
    return l;
  };

  return (
    <MediaQuery
      styles={{ display: 'none' }}
      largerThan={'md'}>
      <ScrollArea
        w={'100vw'}
        h={headerHeight.stickyHeight}
        type='never'>
        <Flex
          direction={'row'}
          px={0}
          justify={'space-evenly;'}
          align={'space-between'}
          miw={'100vw'}
          w={'fit-content'}
          gap={20}
          h={headerHeight.stickyHeight}>
          {links()}
        </Flex>
      </ScrollArea>
    </MediaQuery>
  );
};

export default memo(StickySubHeader);
