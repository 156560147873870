import React, { useEffect, useState } from 'react';
import { formatCfItems } from '@/helpers/formatCfItems';
import { Divider, Flex, Indicator, Stack, Title } from '@mantine/core';
import TextLink from '@/components/Link/TextLink';
import { motion } from 'framer-motion';
import {
  IBadge,
  IBlock,
  IButton,
  IColor,
  ILabel,
  IPath,
} from '@/@types/generated/contentful';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import usePageContext from '@/utils/context/page';
import CustomerAccount from '@/utils/services/CustomerAccount';

const motionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { ease: 'easeOut', duration: 0.6, delay: 0.3 },
};

export default function HeaderDesktopNavigationsMasterNavigation({
  details,
  color,
}: {
  details: IBlock;
  color: IColor;
}) {
  const {
    mainList,
    mainLabel,
    secondaryLabel,
    secondaryList,
    servicesList,
    servicesLabel,
    button,
    account,
  }: {
    button: IButton;
    mainList: IBlock;
    mainLabel: ILabel;
    secondaryLabel: ILabel;
    secondaryList: IBlock;
    servicesList: IBlock;
    servicesLabel: ILabel;
    account?: IPath;
  } = formatCfItems(details);
  const { locale } = usePageContext();
  const [url, setUrl] = useState('');
  useEffect(() => {
    CustomerAccount.getClientUrl(locale).then(setUrl);
  }, [locale]);

  return (
    <Flex
      p={30}
      h='100%'
      direction='column'
      justify='space-between'>
      <motion.div
        {...motionProps}
        initial={false}>
        <Stack spacing={5}>
          {mainLabel && (
            <Title
              order={4}
              mb={10}
              size={18}
              weight='bold'>
              <NoozText
                weight={900}
                size={18}
                component='span'
                color={color?.fields.classname}>
                {`- `}
              </NoozText>
              {mainLabel?.fields.text}
            </Title>
          )}
          {mainList?.fields.items?.map((mainLink, key: number) => {
            const slug = mainLink.fields.slug as IPath;
            const label = mainLink.fields.label as ILabel;
            const badge = mainLink.fields.badge as IBadge;
            if (slug) {
              return (
                <Indicator
                  key={key}
                  sx={{
                    width: 'fit-content',
                    '& > .mantine-Indicator-indicator': {
                      transform: 'translate(115%, -50%)',
                      top: '50%',
                      backgroundColor: badge?.fields.color?.fields?.hex,
                      height: 8,
                      width: 8,
                    },
                  }}
                  color={badge?.fields.color?.fields.classname}
                  disabled={!badge || badge?.fields.key === 'empty'}>
                  <TextLink
                    facet={slug?.fields.facet}
                    url={slug?.fields.url as string}
                    text={label?.fields.text as string}
                    isFadeIn
                    size={16}
                    textProps={{
                      variant: label?.fields.variant || 'text',
                      gradient:
                        label?.fields.variant === 'gradient'
                          ? {
                              from: 'rgb(239, 35, 60)',
                              to: 'rgb(254, 174, 0)',
                              deg: 90,
                            }
                          : null,
                    }}
                  />
                </Indicator>
              );
            }
          })}
          {secondaryLabel && (
            <>
              <Divider my={12} />
              <Title
                order={4}
                mb={10}
                size={16}
                weight='bold'>
                <NoozText
                  weight={900}
                  size={16}
                  component='span'
                  color={color?.fields.classname}>
                  {`- `}
                </NoozText>
                {secondaryLabel?.fields.text}
              </Title>
            </>
          )}
          {secondaryList?.fields.items?.map((secondaryLink, key: number) => {
            const slug = secondaryLink.fields.slug as IPath;
            const label = secondaryLink.fields.label as ILabel;
            if (slug) {
              return (
                <TextLink
                  facet={slug?.fields.facet}
                  url={slug?.fields.url as string}
                  text={label?.fields.text as string}
                  isFadeIn
                  size={16}
                  key={key}
                />
              );
            }
          })}
          {button && (
            <NoozButton
              urlLink={account ? url : undefined}
              target={''}
              rel={''}
              href={
                account ? undefined : (button.fields.slug?.fields.url as string)
              }
              fullWidth={false}
              size='md'
              color={'dark'}
              my={34}>
              {button.fields.label?.fields.text}
            </NoozButton>
          )}
        </Stack>
      </motion.div>
      <motion.div
        {...motionProps}
        transition={{ delay: 0.5 }}>
        {servicesList?.fields.items ? (
          <Stack spacing={5}>
            <Title
              order={4}
              mb={10}
              size={18}
              weight='bold'>
              <NoozText
                weight={900}
                size={18}
                component='span'
                color={color?.fields.classname}>
                {`- `}
              </NoozText>
              {servicesLabel?.fields.text}
            </Title>
            {servicesList.fields.items.map((serviceLink, key: number) => {
              const slug = serviceLink.fields.slug as IPath;
              const label = serviceLink.fields.label as ILabel;
              if (serviceLink.fields.slug) {
                return (
                  <TextLink
                    facet={slug?.fields.facet}
                    url={slug?.fields.url as string}
                    text={label?.fields.text as string}
                    isFadeIn
                    size={16}
                    key={key}
                  />
                );
              }
            })}
          </Stack>
        ) : null}
      </motion.div>
    </Flex>
  );
}
