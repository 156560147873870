//@ts-nocheck
import React, { useContext, useCallback, memo } from 'react';
import { formatCfItems } from '@/helpers/formatCfItems';
import { Box, createStyles, Indicator, useMantineTheme } from '@mantine/core';
import { HeaderActiveTabContext, HeaderDrawerContext } from '../Header';
import HeaderDesktopNavigationsDrawer from './HeaderNavigationsDrawer/HeaderDesktopNavigationsDrawer';
import { TIMING_FUNCTION } from '@/utils/style/animation';
import { useMediaQuery } from '@mantine/hooks';
import useDrawerContext, {
  drawerContextInitState,
} from '@/utils/context/drawer';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { useRouter } from 'next/router';
import useLocalizedSlug from '@/utils/hook/useLocalizedSlug';
import useAuth from '@/utils/context/authentication';

const useStyles = createStyles(() => ({
  text: {
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
  },
  navItemWrapper: {
    position: 'absolute',
    bottom: -18,
    height: 3,
    width: '100%',
    transition: `all 0.2s ${TIMING_FUNCTION}`,
  },
  box: {
    position: 'relative',
  },
  badge: {
    '& .mantine-Indicator-indicator': {
      height: 'fit-content',
      width: 'fit-content',
      padding: '0px 10px',
      top: -2,
      right: -3,
    },
  },
}));

const HeaderDesktopNavigationItem = memo(function HeaderDesktopNavigationItem({
  item,
  activeTabState,
  handleNavHover,
}) {
  const { classes } = useStyles();
  const router = useRouter();
  const { link, color, masterDetails } = item || {};
  const { id } = link || {};
  const localisedLink = useLocalizedSlug(
    link?.fields.slug?.fields.url,
    {},
    link.fields?.slug?.fields?.facet || [],
  );

  const isLinkNavigationItem = !masterDetails;

  const isActive = activeTabState === id;

  const handleOnClick = (e) => {
    if (link.fields.slug?.fields.url) {
      router.push(localisedLink);
    }
  };

  const handleHover = (e) => {
    if (typeof handleNavHover !== 'function') return;
    if (isActive) return;
    handleNavHover(e, item);
  };
  return (
    <Box
      component={isLinkNavigationItem ? 'a' : 'div'}
      className={classes.box}
      id={id}
      key={id}>
      <Box
        className={classes.navItemWrapper}
        sx={{
          background: color?.fields.hex || 'black',
          opacity: isActive ? 1 : 0,
          transform: isActive ? 'translateY(0px)' : 'translateY(4px)',
        }}
      />
      <Indicator
        className={classes.badge}
        disabled={
          !link?.fields.badge || link?.fields.badge?.fields.key === 'empty'
        }
        label={link?.fields.badge?.fields.label?.fields.text}
        color={link?.fields.badge?.fields.color?.fields.classname}>
        <NoozText
          onClick={handleOnClick}
          onMouseEnter={(e) => handleHover(e)}
          style={{ opacity: !isActive ? 0.85 : 1 }}
          weight={500}
          size={16}
          variant={link?.fields.label?.fields.variant || 'text'}
          gradient={color?.fields.gradientObject}
          className={classes.text}
          id={id}>
          {link?.fields.label?.fields.text}
        </NoozText>
      </Indicator>
    </Box>
  );
});

export const HeaderDesktopAccountNavigations = ({ list }) => {
  const { loginLink, accountLink } = formatCfItems(list?.fields.items || {});

  const auth = useAuth();

  return auth?.isAuthenticated ? (
    <HeaderDesktopNavigationItem item={{ link: accountLink }} />
  ) : (
    <HeaderDesktopNavigationItem item={{ link: loginLink }} />
  );
};

export default function HeaderDesktopNavigations({ list, drawerPosition }) {
  const theme = useMantineTheme();
  const isTablet = useMediaQuery(`(max-width:${theme.breakpoints.md})`);

  const { drawer, setDrawer } = useDrawerContext();
  const { headerDrawerState, headerDrawerBodyState } =
    useContext(HeaderDrawerContext);
  const [drawerState, setDrawerState] = headerDrawerState;
  const { headerActiveTabState } = useContext(HeaderActiveTabContext);
  const [activeTabState, setActiveTabState] = headerActiveTabState;
  const [, setDrawerBodyState] = headerDrawerBodyState;

  const { items } = list?.fields || {};

  const handleNavHover = useCallback(
    (event, item) => {
      if (drawer.opened) return;
      const tabletSize = 40;
      const desktopSize = 60;
      const hasSecondaryDetails = item?.secondaryDetails;
      const drawerSize = isTablet
        ? `${hasSecondaryDetails ? tabletSize : tabletSize / 2}vw`
        : `${hasSecondaryDetails ? desktopSize : desktopSize / 2}vw`;
      const currentId = event.target.id;

      if (!item.masterDetails) {
        setDrawerState((prev) => ({
          ...prev,
          opened: false,
        }));
        setActiveTabState(currentId);
      } else if (activeTabState === currentId) {
        setActiveTabState(null);
        setDrawerState((prev) => ({
          ...prev,
          opened: false,
        }));
      } else {
        setActiveTabState(currentId);
        setTimeout(() => {
          setDrawer(drawerContextInitState.drawer);
          setDrawerBodyState(
            <>
              <HeaderDesktopNavigationsDrawer details={item} />
            </>,
          );
          setDrawerState({
            ...drawerState,
            position: drawerPosition || 'left',
            onClose: () => {
              setActiveTabState(null);
              setDrawerState((prev) => ({
                ...prev,
                opened: false,
              }));
            },
            opened: true,
            size: drawerSize,
          });
        }, 50);
      }
    },
    [
      drawer,
      activeTabState,
      drawerState,
      isTablet,
      setDrawer,
      setDrawerBodyState,
    ],
  );

  return items?.map((item, index) => {
    return (
      <HeaderDesktopNavigationItem
        key={index}
        item={formatCfItems(item)}
        activeTabState={activeTabState}
        handleNavHover={handleNavHover}
      />
    );
  });
}
