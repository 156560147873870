import React, { useContext, useMemo } from 'react';
import {
  ActionIcon,
  Center,
  Divider,
  Flex,
  createStyles,
  useMantineTheme,
  clsx,
  Image,
} from '@mantine/core';
import Link from '@/components/Link/Link';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import usePageContext from '@/utils/context/page';
import { CategoryFilterSectionContext } from '@/templates/ProductCategory';
import {
  SHOP_ITEMS_ACTION,
  useShopItems,
  useShopItemsDispatch,
} from '@/templates/ProductCategory.context';
import { AdjustmentsHorizontal } from 'tabler-icons-react';
import useStaticContent from '@/utils/hook/useStaticContent';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { useMediaQuery } from '@mantine/hooks';
import useCollectionPageType from '@/utils/hook/useCollectionPageType';
import {
  linearHotGradientText,
  useHighlightStyles,
} from '@/utils/style/useHighlightStyles';
import { IButton, IImage } from '@/@types/generated/contentful';
import { Entry } from 'contentful';

const ButtonTextProps = {
  size: 14,
  weight: 500,
};

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingBottom: 8,
    columnGap: 20,
    overflowX: 'auto',
    overflowY: 'hidden',
    [theme.fn.smallerThan('md')]: {
      columnGap: 15,
    },
  },
  buttonLeftIcon: {
    borderRadius: '100%',
    overflow: 'hidden',
  },
}));

export const CategoryFilterButton = ({ isOpen }: { isOpen?: boolean }) => {
  const { nbFilters } = useShopItems();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const categoryFilter = useContext(CategoryFilterSectionContext);
  const { categoryFilters } = useStaticContent();
  const { openFilters, closeFilters } = categoryFilters;
  const dispatch = useShopItemsDispatch();

  return (
    <>
      <NoozButton
        color='black'
        leftIcon={<AdjustmentsHorizontal strokeWidth={1} />}
        rightIcon={
          nbFilters > 0 ? (
            <Center
              sx={{ width: 22, borderRadius: 100, backgroundColor: 'white' }}>
              <NoozText
                {...ButtonTextProps}
                color='black'>
                {nbFilters}
              </NoozText>
            </Center>
          ) : null
        }
        size={isMobile ? 'sm' : 'md'}
        variant='filled'
        onClick={categoryFilter.toggle}>
        <NoozText {...ButtonTextProps}>
          {isOpen ? closeFilters : openFilters}
        </NoozText>
      </NoozButton>
      {nbFilters > 0 ? (
        <ActionIcon
          radius='xl'
          size={isMobile ? 36 : 42}
          variant='outline'
          onClick={() => {
            dispatch({
              type: SHOP_ITEMS_ACTION.RESET,
            });
          }}>
          <NoozIcon iconKey='Trash' />
        </ActionIcon>
      ) : null}
    </>
  );
};

const CategoryHeaderNavigation = () => {
  const { nbFilters, activeFilters } = useShopItems();
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const { productCategory, page, components } = usePageContext();
  const isPackCategory = productCategory?.fields.handle?.includes('Packs');
  const collectionPageType = useCollectionPageType();
  const highLightColors = useHighlightStyles();
  const dispatch = useShopItemsDispatch();

  const showFilterButton =
    (!isPackCategory && !(collectionPageType === 'split')) || nbFilters > 0;

  const renderButton = (
    label: string,
    url: string,
    image?: IImage,
    gradientColor?: string,
  ) => (
    <Link href={url}>
      <NoozButton
        color={'black'}
        size={isMobile ? 'sm' : 'md'}
        pl={image && 8}
        variant={'outline'}
        sx={
          gradientColor
            ? {
                background: gradientColor,
                color: 'white !important',
                borderColor: 'white !important',
              }
            : undefined
        }
        leftIcon={
          image && (
            <Flex
              className={classes.buttonLeftIcon}
              w={30}
              h={30}
              justify='center'
              align='center'
              bg='black'>
              <Image
                imageProps={{ height: '100%' }}
                height='100%'
                // pos={'center'}
                fit='fill'
                src={image?.fields.media?.fields.file.url + '?fm=webp'}
                alt={image?.fields.altText}
              />
            </Flex>
          )
        }>
        <NoozText {...ButtonTextProps}>{label}</NoozText>
      </NoozButton>
    </Link>
  );

  const renderSubCategoryButtons = () =>
    productCategory?.fields.subCategory?.map((subCategory, index) => {
      return (
        <React.Fragment key={index}>
          {renderButton(
            subCategory?.fields?.shortLabel?.fields?.text as string,
            subCategory?.fields?.pageReference?.fields?.slug.fields
              .url as string,
            subCategory?.fields?.miniImage as IImage,
            subCategory?.fields?.gradientColor?.fields.hex,
          )}
        </React.Fragment>
      );
    });

  const renderSubCollectionButtons = () =>
    productCategory?.fields.subCollections?.map((subCollection, index) => (
      <React.Fragment key={index}>
        {renderButton(
          subCollection?.fields?.label?.fields?.text as string,
          (subCollection?.fields as any)?.page?.fields?.slug.fields
            .url as string,
        )}
      </React.Fragment>
    ));

  const renderPageButtons = () => {
    const buttons = (
      components as unknown as Record<string, Entry<any>>[]
    )?.find((c) => (c.fields as any).key === 'buttons');
    if (!buttons || (page as any).fields.pageType.type !== 'ProductFacet')
      return null;

    return (buttons.fields as any).items?.map(
      (item: IButton, index: number) => (
        <React.Fragment key={index}>
          {renderButton(
            item.fields?.label?.fields?.text as string,
            item.fields?.slug?.fields.url as string,
          )}
        </React.Fragment>
      ),
    );
  };

  const renderSubFacetButtons = () => {
    return productCategory?.fields.subFacets?.map((facet, index) => {
      // const values = (facet.fields.value as string).split(',');
      // // Construct filters for each value
      // const filters = values.map(
      //   (value) => `${facet.fields.attribute}:${value.trim()}`,
      // );
      // const isBestSeller = filters.includes(
      //   'defaultProductVariant.fields.bestSeller:true',
      // );

      // Check if any of the filters are active
      const values = (facet.fields.value || '').split(',');
      const isActive = values.reduce((active, v) => {
        if (!activeFilters[facet.fields.attribute as string]?.includes(v))
          return false;
        return active;
      }, true);
      // activeFilters[
      //   facet.fields.attribute as string
      // ]?.includes?.(facet.fields.value as string);

      if (!facet?.fields?.label?.fields?.text) {
        return null;
      }

      return (
        <React.Fragment key={index}>
          <NoozButton
            variant={
              facet?.fields?.label?.fields?.color?.fields?.gradientObject
                ? 'outline'
                : !facet?.fields?.color
                ? isActive
                  ? 'filled'
                  : 'outline'
                : 'filled'
            }
            className={clsx(
              // isBestSeller ? highLightColors.classes.outlineButton : null,
              { active: isActive },
            )}
            sx={
              facet?.fields?.label?.fields?.color?.fields?.gradientObject
                ? {
                    background:
                      facet?.fields?.label?.fields?.color?.fields?.hex,
                    color: 'white !important',
                    borderColor: 'white !important',
                  }
                : facet?.fields?.color && isActive
                ? { backgroundColor: `#4a4a4a !important` }
                : undefined
            }
            onClick={() => {
              dispatch({
                type: SHOP_ITEMS_ACTION.SET_FILTER,
                payload: {
                  attribute: facet.fields.attribute as string,
                  value: facet.fields.value as string,
                },
              });
            }}
            color={
              facet?.fields?.color?.fields?.gradientObject
                ? 'black'
                : (facet?.fields.color?.fields.classname as
                    | string
                    | undefined) || 'black'
            }
            size={isMobile ? 'sm' : 'md'}>
            <NoozText
              {...ButtonTextProps}
              fw={500}
              style={
                // isBestSeller && !isActive ? linearHotGradientText :
                undefined
              }>
              {(facet as any)?.fields?.label?.fields?.text}
            </NoozText>
          </NoozButton>
        </React.Fragment>
      );
    });
  };

  const items = [
    showFilterButton ? (
      <React.Fragment key={'CategoryFilterButton'}>
        <CategoryFilterButton />
      </React.Fragment>
    ) : null,
    renderPageButtons() ? (
      <React.Fragment key={'renderPageButtons'}>
        {renderPageButtons()}
      </React.Fragment>
    ) : null,
    !productCategory?.fields.hideSubCategoriesLink &&
    (productCategory as any)?.fields.subCategory?.length > 0 ? (
      <React.Fragment key={'renderSubCategoryButtons'}>
        {renderSubCategoryButtons()}
      </React.Fragment>
    ) : null,
    (productCategory as any)?.fields.subFacets?.length > 0 ? (
      <React.Fragment key={'renderSubFacetButtons'}>
        {renderSubFacetButtons()}
      </React.Fragment>
    ) : null,
    (productCategory as any)?.fields.subCollections?.length > 0 ? (
      <React.Fragment key={'renderSubCollectionButtons'}>
        {renderSubCollectionButtons()}
      </React.Fragment>
    ) : null,
  ]
    .filter((f) => f)
    .reduce((prev: any[], element, idx) => {
      if (prev.length > 0)
        prev.push(
          <Divider
            key={`divider-${idx}`}
            orientation='vertical'
          />,
        );
      prev.push(element);
      return prev;
    }, []);

  return (
    <Flex
      className={classes.wrapper}
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}>
      {items}
    </Flex>
  );
};

export default CategoryHeaderNavigation;
