import React, { useContext, useEffect, useMemo, useState } from 'react';
import { COUNTRIES, COUNTRY_COOKIE_NAME } from '@/helpers/Locale';
import useDrawerContext from '@/utils/context/drawer';
import usePageContext from '@/utils/context/page';
import { Box, Group, Image, LoadingOverlay, NativeSelect } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useRouter } from 'next/router';
import { Language } from 'tabler-icons-react';
import { ICountryDictionary } from '@/helpers/interface';
import LocaleHelper from '@/helpers/Locale';
import Cookies from 'js-cookie';
import { expiresCountryOrigin } from '@/helpers/Cookie';
import { HeaderDrawerContext } from '@/components/Header/Header';

interface FormValues {
  country: keyof typeof COUNTRIES;
  language: string;
}

const LocalisationMini = () => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const { locale, localizedSlugs } = usePageContext();

  const { headerDrawerState } = useContext(HeaderDrawerContext);
  const { resetDrawer } = useDrawerContext();
  const initialValues = useMemo(() => {
    const { country, language } = LocaleHelper.splitInfoWithInt(
      locale as string,
      false,
    ) as any;
    return {
      country:
        (['int', 'fr'].includes(country) &&
        Cookies.get(COUNTRY_COOKIE_NAME)?.toLowerCase()
          ? Cookies.get(COUNTRY_COOKIE_NAME)?.toLowerCase()
          : country) || country,
      language,
    };
  }, [locale]);

  const form = useForm<FormValues>({
    initialValues,
  });

  const country: keyof typeof COUNTRIES = form.values.country;

  const countryConfig = COUNTRIES[country] || COUNTRIES['int'];

  const onChangeCountry = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e?.currentTarget?.value) {
      // @ts-ignore
      const newLocale = `${COUNTRIES[e.currentTarget.value].languages[0].key}-${
        // @ts-ignore
        e.currentTarget.value
      }`;

      if (e.currentTarget.value === 'int') {
        Cookies.remove(COUNTRY_COOKIE_NAME);
      } else {
        Cookies.set(COUNTRY_COOKIE_NAME, e.currentTarget.value.toUpperCase(), {
          expires: expiresCountryOrigin(),
        });
      }
      router
        .push(
          `/${newLocale}/${
            localizedSlugs?.[
              LocaleHelper.getValidContentfulLocale(newLocale as string)
            ] ||
            localizedSlugs?.en ||
            ''
          }`,
        )
        .then(() => {
          setIsLoading(false);
        });

      headerDrawerState[1]((prev) => {
        return {
          ...prev,
          opened: false,
        };
      });
    }
  };
  const onChangeLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e?.currentTarget?.value) {
      const newLocale = LocaleHelper.getLocale(
        `${e?.currentTarget?.value}-${country}`,
      );
      router
        .push(
          `/${newLocale}/${
            localizedSlugs?.[
              LocaleHelper.getValidContentfulLocale(newLocale as string)
            ] ||
            localizedSlugs?.en ||
            ''
          }`,
        )
        .then(() => {
          resetDrawer();
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    form.setFieldValue('language', countryConfig.defaultLanguage);
  }, [country]);

  return (
    <Box
      sx={() => ({
        width: '100%',
      })}>
      <LoadingOverlay
        visible={isLoading}
        overlayBlur={2}
      />
      <Group>
        <NativeSelect
          radius={'xl'}
          data={
            Object.keys(COUNTRIES)?.map((c) => ({
              label:
                // @ts-ignore
                COUNTRIES[c as keyof typeof COUNTRIES].labelTranslations[
                  (locale?.split('-')[0] || 'en') as keyof ICountryDictionary
                ],
              value: c,
            })) || []
          }
          icon={
            // @ts-ignore
            COUNTRIES[country]?.flag ? (
              <Image
                // @ts-ignore
                src={COUNTRIES[country]?.flag}
                alt={`${country} country flag`}
                width={15}
              />
            ) : undefined
          }
          value={country}
          onChange={onChangeCountry}
        />

        <NativeSelect
          radius={'xl'}
          data={
            COUNTRIES[country]?.languages.map((l: Record<string, any>) => ({
              value: l.key,
              label: l.label,
            })) || []
          }
          disabled={COUNTRIES[country]?.languages.length === 1}
          icon={<Language size={14} />}
          onChange={onChangeLanguage}
        />
      </Group>
    </Box>
  );
};

export default LocalisationMini;
