import {
  Box,
  Image,
  clsx,
  createStyles,
  keyframes,
  useMantineTheme,
} from '@mantine/core';
import React, { useEffect, useMemo, useState } from 'react';
import { ShopItemVariant } from '@/@types/shopitem';
import { useMediaQuery } from '@mantine/hooks';

const fadeIn = {
  2: keyframes`
  0%, 50% { opacity: 1; }
  50.01%, 100% { opacity: 0; }
`,
  3: keyframes`
  0%, 33.33% { opacity: 1; }
  33.34%, 100% { opacity: 0; }
`,
  4: keyframes`
  0%, 25% { opacity: 1; }
  25.01%, 100% { opacity: 0; }
  `,
  5: keyframes`
  0%, 20% { opacity: 1; }
  20.01%, 100% { opacity: 0; }
`,
};

const fadeInStyle = (delay: number, duration: number) => ({
  animation: `${
    fadeIn[duration as keyof typeof fadeIn]
  } ${duration}s linear ${delay}s infinite`,
});
const useStyles = createStyles((theme) => ({
  imagesWrapper: {
    width: '100%',
    aspectRatio: '1',
    position: 'relative',
    '&:hover #main-picture': {
      opacity: 0,
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        opacity: 1,
      },
    },
  },
  hoverImg: {
    top: 0,
    position: 'absolute',
    opacity: 0,
  },
  unique: {
    opacity: 1,
  },
}));

interface CarouselProps {
  hovered?: boolean;
  images: string[];
  variantId?: string;
}

const Carousel: React.FC<CarouselProps> = ({ hovered, images, variantId }) => {
  const { classes } = useStyles();

  return (
    <>
      {images.map((img, key) => (
        <Image
          key={key}
          src={img + '?fm=webp'}
          alt={img}
          className={clsx(
            classes.hoverImg,
            images.length === 1 && hovered && classes.unique,
          )}
          sx={
            hovered && images.length > 1
              ? { ...fadeInStyle(key, images.length) }
              : undefined
          }
        />
      ))}
    </>
  );
};

interface ProductCardImagesProps extends ShopItemVariant {
  productLifestyleImages: string[];
  hovered?: boolean;
  variantId?: string;
  premiumEnabled?: boolean;
}

const ProductCardImages: React.FC<ProductCardImagesProps> = ({
  hovered,
  productLifestyleImages,
  variantId,
  premiumEnabled,
  ...variant
}) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const mainDefaultPic = useMemo(() => variant.posterImages[0], [variant]);
  const mainHoverPic = useMemo(
    () =>
      premiumEnabled
        ? variant.posterImages[3] || variant.posterImages[1]
        : variant.posterImages[1],
    [variant],
  );
  // [X] comportement en mobile, pas de hover
  // [ ] blink marvin black / sara blue on hover desktop
  // [X] réduire badge new en mobile
  const hoveredImages = useMemo(() => {
    const lifestyles =
      productLifestyleImages?.length > 0
        ? productLifestyleImages
        : variant.lifestyleImages;

    return [mainHoverPic, ...(lifestyles || [])].filter(Boolean);
  }, [variant, productLifestyleImages, mainHoverPic]);
  return (
    <Box
      className={classes.imagesWrapper}
      id={'img-wrapper'}>
      <Image
        id={'main-picture'}
        src={mainDefaultPic + '?fm=webp'}
        alt={variant.title}
      />
      {isMobile ? null : (
        <Carousel
          hovered={hovered}
          images={hoveredImages}
          variantId={variantId}
        />
      )}
    </Box>
  );
};

export default ProductCardImages;
