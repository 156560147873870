import React from 'react';
import {
  Box,
  Container,
  Divider,
  Flex,
  Group,
  MediaQuery,
  Title,
  clsx,
  createStyles,
  useMantineTheme,
  Image,
} from '@mantine/core';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import usePageContext from '@/utils/context/page';
import CategoryHeaderSticky from './CategoryHeaderSticky';
import CategoryHeaderNavigation from './CategoryHeaderNavigations';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { useContainerStyles } from '@/utils/style/useContainer';
import NoozBadge from '@/components/Nooz/NoozBadge/NoozBadge';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { useMediaQuery } from '@mantine/hooks';
import { useFilteredShopItems } from '@/templates/ProductCategory.context';

const useStyles = createStyles((theme) => ({
  containerWrapper: {
    paddingBottom: 42,
  },
  breadcrumbsWrapper: {
    paddingTop: 30,
    paddingBottom: 30,
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      paddingTop: 18,
      paddingBottom: 10,
    },
  },
  title: {
    fontSize: 48,
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      fontSize: 38,
      marginTop: 10,
    },
  },
  subLabel: {
    marginTop: 20,
    marginBottom: 30,
    fontSize: 24,
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      fontSize: 18,
      marginTop: 10,
      marginBottom: 25,
    },
  },
}));

const CategoryHeader = () => {
  const items = useFilteredShopItems();
  const { classes } = useStyles();
  const containerStyle = useContainerStyles();
  const { productCategory, breadcrumbs } = usePageContext();
  const theme = useMantineTheme();
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);

  return (
    <>
      <CategoryHeaderSticky />
      {/*{productCategory?.fields.heroImage?.fields.media ? (*/}
      {/*  <Image*/}
      {/*    src={*/}
      {/*      productCategory.fields.heroImage.fields.media?.fields.file.url +*/}
      {/*      '?fm=webp'*/}
      {/*    }*/}
      {/*    alt={'hero'}*/}
      {/*    sx={{*/}
      {/*      [theme.fn.largerThan('sm')]: {*/}
      {/*        display: 'none',*/}
      {/*      },*/}
      {/*    }}*/}
      {/*  />*/}
      {/*) : null}*/}
      <Container className={clsx(containerStyle.classes.container)}>
        <Box className={classes.breadcrumbsWrapper}>
          <Breadcrumbs list={breadcrumbs} />
        </Box>
        <Flex
          align={'center'}
          justify='space-between'>
          <Title
            order={1}
            sx={{
              fontFamily: 'Mont',
              fontWeight: 900,
              display: 'flex',
              alignItems: 'center',
            }}
            className={classes.title}>
            <span>{productCategory?.fields.label?.fields.text}</span>
            <Box
              component='span'
              sx={(theme) => ({
                background:
                  theme.colors[
                    productCategory?.fields.color?.fields?.classname as string
                  ]?.[0] || theme.colors.dark[2],
                fontSize: 14,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 20,
                minWidth: 40,
                color: 'white',
                borderRadius: 100,
                fontWeight: 300,
                marginLeft: 20,
              })}>
              {items.quantity}
            </Box>
          </Title>
          {/**@ts-ignore*/}
          {productCategory?.fields.usps && (
            <Group
              align='center'
              sx={(theme) => ({
                [theme.fn.smallerThan(theme.breakpoints.md)]: {
                  display: 'none',
                },
              })}
              spacing={isDesktop ? 'lg' : 'xs'}>
              {/**@ts-ignore*/}
              {productCategory?.fields.usps?.map((usp, index) => {
                return (
                  <Box key={index}>
                    <NoozBadge
                      badgeProps={{
                        size: 'xl',
                        variant: 'outline',
                        color: 'gray',
                      }}
                      textProps={{
                        size: 14,
                        color: 'dimmed',
                        weight: 500,
                      }}
                      iconPosition='right'
                      icon={
                        <NoozIcon
                          color={'gray'}
                          size={17}
                          iconKey={
                            usp.fields.badge?.fields.icon?.fields?.iconKey
                          }
                        />
                      }
                      text={
                        isDesktop
                          ? (usp.fields.badge?.fields.label?.fields
                              ?.text as string)
                          : ''
                      }
                    />
                  </Box>
                );
              })}
            </Group>
          )}
        </Flex>
        <NoozText
          component={'h2'}
          weight={500}
          className={classes.subLabel}
          color={'titleDimmed'}>
          {productCategory?.fields.subLabel?.fields.text}
        </NoozText>
      </Container>
      <Container
        className={containerStyle.classes.containerLeftFullWidth}
        sx={(theme) => ({
          marginBottom: 42,
          [theme.fn.smallerThan('md')]: {
            paddingRight: 0,
            marginBottom: 0,
          },
        })}>
        <div id='desktop-sticky-category-navigation'>
          <CategoryHeaderNavigation />
        </div>
      </Container>
      <Container>
        <MediaQuery
          largerThan={'md'}
          styles={{ display: 'none' }}>
          <span>
            <Divider
              my={25}
              mb={42}
            />
          </span>
        </MediaQuery>
      </Container>
    </>
  );
};

export default CategoryHeader;
