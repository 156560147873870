import React, { memo, useMemo } from 'react';
import {
  ColorSwatch,
  Divider,
  Group,
  Stack,
  useMantineTheme,
} from '@mantine/core';
import usePageContext from '@/utils/context/page';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import {
  SHOP_ITEMS_ACTION,
  useShopItems,
  useShopItemsDispatch,
} from '@/templates/ProductCategory.context';

const CategoryFilter = memo(
  ({
    attribute,
    values,
    label,
  }: {
    attribute: string;
    label: string;
    values: any[];
  }) => {
    const { activeFilters } = useShopItems();
    const { productCategory } = usePageContext();
    const dispatch = useShopItemsDispatch();
    const theme = useMantineTheme();

    const rightIcon = (facet: any) => {
      if (facet.colorHex) {
        return (
          <ColorSwatch
            size={14}
            color={facet.colorHex}
            sx={{ color: '#fff', cursor: 'pointer' }}
          />
        );
      }
      if (facet.iconKey) {
        return (
          <NoozIcon
            size={16}
            strokeWidth={2}
            color={
              theme.colors[
                productCategory?.fields.color?.fields.classname || ''
              ]?.[0] || 'gray'
            }
            iconKey={facet.iconKey}
          />
        );
      }
      return null;
    };

    return values && (values.length > 1 || attribute === 'promotion') ? (
      <Stack>
        <Divider label={<NoozText size={14}>{label}</NoozText>} />
        <Group spacing='xs'>
          {values.map((entry: any) => {
            const isChecked = (activeFilters[attribute] || []).includes(
              entry.value,
            );
            return entry.label ? (
              <NoozButton
                onClick={() => {
                  dispatch({
                    type: SHOP_ITEMS_ACTION.SET_FILTER,
                    payload: {
                      attribute,
                      value: entry.value,
                    },
                  });
                }}
                rightIcon={rightIcon(entry)}
                key={entry.id}
                color='black'
                variant={isChecked ? 'filled' : 'outline'}
                size='lg'
                sx={{ paddingLeft: 10 }}
                compact>
                <NoozText
                  weight={600}
                  size={12}
                  transform='uppercase'>
                  {entry.label as string}
                </NoozText>
              </NoozButton>
            ) : null;
          })}
        </Group>
      </Stack>
    ) : null;
  },
);

export default CategoryFilter;
